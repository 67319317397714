import React, {Fragment, useEffect} from "react";
import styled from "styled-components";
import {isEmpty} from "lodash";
import icon_stats from "assets/img/icons/stats.svg";
import {useDispatch, useSelector} from "react-redux";
import {getFormGuideState} from "modules/selectors";
import {fetchFormGuide} from "modules/actions";
import {getSportState} from "modules/selectors/sport";
import {FormGuide} from "components/FormGuide";

export const StatsButtonStyled = styled.button`
	border: 0;
	padding: 5px 20px 5px 0;
	margin: 0;
	color: #656565;
	background: url(${icon_stats}) no-repeat right center;
	font-weight: 700;
	font-size: 10px;
	line-height: 15px;
	text-align: right;
	height: 25px;
`;

export interface IStatsButton {
	toggleStats?: () => void;
}

interface IStatsIds {
	id: number;
	home_squad_id: number;
	away_squad_id: number;
}

export type IStatsButtonWithSquad = IStatsIds & IStatsButton;

export const StatsButton: React.FC<IStatsButton> = ({toggleStats}) => {
	const {isFormGuide} = useSelector(getSportState);

	if (!isFormGuide) {
		return null;
	}
	return <StatsButtonStyled onClick={toggleStats}>Stats</StatsButtonStyled>;
};

interface IFormProps {
	roundId: number;
	home_squad_id: number;
	away_squad_id: number;
}
const FormGuideStats: React.FC<IFormProps> = ({roundId, home_squad_id, away_squad_id}) => {
	const dispatch = useDispatch();
	const formGuide = useSelector(getFormGuideState);

	const {sportName} = useSelector(getSportState);

	useEffect(() => {
		if (isEmpty(formGuide)) {
			dispatch(fetchFormGuide());
		}
	}, [dispatch, formGuide]);

	return (
		<FormGuide sportName={sportName} homeSquadId={home_squad_id} awaySquadId={away_squad_id} />
	);
};

export const Stats: React.FC<IStatsButtonWithSquad> = ({
	id,
	home_squad_id,
	away_squad_id,
	toggleStats,
}) => {
	return (
		<Fragment>
			<FormGuideStats
				roundId={id}
				home_squad_id={home_squad_id}
				away_squad_id={away_squad_id}
			/>
		</Fragment>
	);
};
