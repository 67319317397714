import {
	fetchRoundsFailed,
	fetchRoundsSuccess,
	fetchSquadsFailed,
	fetchSquadsSuccess,
	fetchStatesFailed,
	fetchStatesSuccess,
	fetchLadderSuccess,
	fetchLadderFailed,
	fetchRoundStatsSuccess,
	fetchRoundStatsFailed,
	fetchSelectedRoundSuccess,
	fetchSelectedRoundFail,
	fetchMRECSuccess,
	fetchMRECFailed,
	fetchNotificationBarSuccess,
	fetchNotificationBarFailed,
	fetchVenuesSuccess,
	fetchVenuesFailed,
	fetchFormGuideSuccess,
	fetchFormGuideFailed,
	fetchChecksumsSuccess,
	fetchChecksumsFailed,
} from "modules/actions";
import {get} from "lodash";
import {getRounds} from "modules/selectors";
import {Api} from "modules/utils/Api";
import {put, call, select} from "typed-redux-saga/macro";
import {SagaIterator} from "redux-saga";
import {ISagaAction, IPicksPayload} from "modules/types";

export const fetchStatesSaga = function* () {
	try {
		const response = yield* call(Api.JSON.states);
		yield* put(fetchStatesSuccess(response));
	} catch (e) {
		yield* put(fetchStatesFailed(e));
	}
};

export const fetchSquadsSaga = function* () {
	try {
		const response = yield* call(Api.JSON.squads);
		yield* put(fetchSquadsSuccess(response));
	} catch (e) {
		yield* put(fetchSquadsFailed(e));
	}
};

export const fetchRoundsSaga = function* () {
	try {
		const response = yield* call(Api.JSON.rounds);
		yield* put(fetchRoundsSuccess(response));
	} catch (e) {
		yield* put(fetchRoundsFailed(e));
	}
};

export const fetchLadderSaga = function* () {
	try {
		const response = yield* call(Api.JSON.ladder);
		const arrayResponse = Object.keys(response).map((position) => {
			return get(response, `${position}`);
		});
		yield* put(fetchLadderSuccess(arrayResponse));
	} catch (e) {
		yield* put(fetchLadderFailed(e));
	}
};

export const fetchRoundStatsSaga = function* ({payload}: ISagaAction<IPicksPayload>): SagaIterator {
	try {
		const response = yield* call(Api.JSON.round_stats, payload);
		yield* put(fetchRoundStatsSuccess(response));
	} catch (e) {
		yield* put(fetchRoundStatsFailed(e));
	}
};

export const fetchSelectedRoundSaga = function* () {
	try {
		const rounds = yield* select(getRounds);
		const firstActive = rounds.find(
			(round) => round.status === "active" || round.status === "scheduled"
		);

		if (firstActive) {
			const roundID = get(firstActive, "id", 1);
			yield* put(fetchSelectedRoundSuccess({round: roundID}));
		} else {
			const length = rounds.length;
			const lastRound = rounds[length - 1];
			const selected_round = get(lastRound, "id", 0);
			yield* put(fetchSelectedRoundSuccess({round: selected_round}));
		}
	} catch (err) {
		yield* put(fetchSelectedRoundFail());
	}
};

export const fetchMRECSaga = function* () {
	try {
		const response = yield* call(Api.JSON.mrec);
		yield* put(fetchMRECSuccess(response));
	} catch (err) {
		yield* put(fetchMRECFailed(err));
	}
};

export const fetchNotificationBarSaga = function* () {
	try {
		const response = yield* call(Api.JSON.notification_bar);
		yield* put(fetchNotificationBarSuccess(response.notifications));
	} catch (err) {
		yield* put(fetchNotificationBarFailed(err));
	}
};

export const fetchVenueSaga = function* () {
	try {
		const response = yield* call(Api.JSON.venues);
		yield* put(fetchVenuesSuccess(response));
	} catch (err) {
		yield* put(fetchVenuesFailed(err));
	}
};

export const fetchFormGuideSaga = function* () {
	try {
		const response = yield* call(Api.JSON.form_guide);
		yield* put(fetchFormGuideSuccess(response));
	} catch (err) {
		yield* put(fetchFormGuideFailed(err));
	}
};

export const fetchChecksumsSaga = function* () {
	try {
		const response = yield* call(Api.JSON.checksums);
		yield put(fetchChecksumsSuccess(response));
	} catch (e) {
		yield put(fetchChecksumsFailed(e));
	}
};
