import {createAction} from "redux-act";
import {IError, IUser} from "modules/types";

export const userFetch = createAction();
export const userFetchSuccess = createAction<IUser>();
export const userFetchFailed = createAction<IError>();

export interface IUserLoginPayload {
	email: string;
	password: string;
}

export interface IUserRegistrationPayload {
	email: string;
	password: string;
	name: string;
	state: string;
	username: string;
	birthday: string;
}

export interface IUserLoginSSOPayload {
	code: string;
	verifier: string;
	nonce: string;
	mobile_app: number;
}

export interface IUserRegistrationSSOPayload {
	code: string;
	verifier: string;
}

export interface IUserRegisterForGamePayload {
	team_name: string;
	team_supported: number;
}

export interface IUserRecoverForGamePayload {
	team_name: string;
	team_supported: number;
	terms: boolean;
}

export interface IUserUpdatePayload {
	team_name: string;
	isWeeklyReminderEnabled: boolean;
	disabledNotifications: boolean;
	successMsg?: string;
}

export interface IUserPasswordUpdatePayload {
	password: string;
	successMsg?: string;
}

export interface IUserCommunicationsUpdatePayload {
	disabled_notifications: boolean;
	weekly_reminders: boolean;
	successMsg?: string;
}

export interface IUserPreregisterPayload {
	name: string;
	email: string;
}

export interface IUserPreregisterSuccess {
	message: string;
}

export const userLogin = createAction<IUserLoginPayload>();
export const userLoginSuccess = createAction<IUser>();
export const userLoginFailed = createAction<IError>();

export const userPreregister = createAction<IUserPreregisterPayload>();
export const userPreregisterSuccess = createAction<IUserPreregisterSuccess>();
export const userPreregisterFailed = createAction<IError>();

export const userRegistration = createAction<IUserRegistrationPayload>();
export const userRegistrationSuccess = createAction<IUser>();
export const userRegistrationFailed = createAction<IError>();

export const userLoginSSO = createAction<IUserLoginSSOPayload>();
export const userLoginSSOSuccess = createAction<IUser>();
export const userLoginSSOFailed = createAction<IError>();

export const userRegistrationSSO = createAction<IUserRegistrationSSOPayload>();
export const userRegistrationSSOSuccess = createAction<IUser>();
export const userRegistrationSSOFailed = createAction<IError>();

export const userRegisterForGame = createAction<IUserRegisterForGamePayload>();
export const userRegisterForGameSuccess = createAction<IUser>();
export const userRegisterForGameFailed = createAction<IError>();

export const recoverUserForGame = createAction<IUserRecoverForGamePayload>();
export const recoverUserForGameSuccess = createAction<IUser>();
export const recoverUserForGameFailed = createAction<IError>();

export const userLogout = createAction();
export const userLogoutSuccess = createAction();
export const userLogoutFailed = createAction<string>();

export const userUpdate = createAction<IUserUpdatePayload>();
export const userUpdateSuccess = createAction<IUser>();
export const userUpdateFailed = createAction<IError>();
export const userUpdateReset = createAction();

export const userPasswordUpdate = createAction<IUserPasswordUpdatePayload>();
export const userCommunicationsUpdate = createAction<IUserCommunicationsUpdatePayload>();

export const checkUsername = createAction<string>();
export const clearUserError = createAction();
export const setUserError = createAction<IError>();

export interface IPWDResetRequestParams {
	email: string;
}

export const postPWDResetRequest = createAction<IPWDResetRequestParams>();
export const postPWDResetRequestSuccess = createAction();
export const postPWDResetRequestFailed = createAction<IError>();

export interface IPWDResetParams {
	token: string;
	password: string;
}

export const passwordResetClearForm = createAction();
export const requestPWDReset = createAction<IPWDResetParams>();
export const requestPWDResetSuccess = createAction();
export const requestPWDResetFailed = createAction<IError>();

export const postDeactivateUser = createAction();

export interface IContactPayload {
	type: string;
	name: string;
	email: string;
	question: string;
}

export const postContact = createAction<IContactPayload>();
export const postContactFailed = createAction<IError>();
export const postContactSuccess = createAction();
export const clearContact = createAction();

export interface IUserAlert {
	text?: string;
}

export const showUserAlert = createAction<IUserAlert>();
export const hideUserAlert = createAction();

export const setPageHeader = createAction<string>();
export const clearPageHeader = createAction();

export const updateSecret = createAction();

export const fetchUserCountry = createAction();
export const fetchUserCountrySuccess = createAction<string>();
