import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {
	chain,
	filter,
	find,
	last,
	matches,
	memoize,
	findLast,
	get,
	first,
	groupBy,
	orderBy,
	mapValues,
	keyBy,
} from "lodash";
import moment from "moment";
import {RoundStatus, MatchStatus} from "modules/utils";

const DEFAULT_NOTIFICATION = {
	sport: "bbl",
	isEnabled: false,
	message: "",
};
const DEFAULT_MREC = {
	sport: "bbl",
	isEnabled: false,
	message: "",
};

// Quebec is GEO blocked, so we need to hide it from states
export const getStates = ({jsons: {states}}: IStore) =>
	filter(states, (state, key) => key !== "QC");
export const getSquads = ({jsons: {squads}}: IStore) =>
	squads.sort((a, b) => a.name.localeCompare(b.name));
export const getRounds = ({jsons: {rounds}}: IStore) => rounds;
export const getLadder = ({jsons: {ladder}}: IStore) => ladder;
export const getRoundStats = ({jsons: {round_stats}}: IStore) => round_stats;
export const getMRECState = ({jsons: {mrec}}: IStore) => mrec;
export const getSelectedRoundID = ({jsons: {selected_round}}: IStore) => selected_round;
export const getNotificationBarState = ({jsons: {notification_bar}}: IStore) => notification_bar;
export const getVenuesState = ({jsons: {venues}}: IStore) => venues;
export const getFormGuideState = ({jsons: {formguide}}: IStore) => formguide;

export const getScheduledRounds = createSelector(
	getRounds,
	(rounds) => filter(rounds, ({status}) => status === RoundStatus.Scheduled) || [last(rounds)]
);

export const getRoundBySelectedID = createSelector(getRounds, getSelectedRoundID, (rounds, id) => {
	const roundsByID = keyBy(rounds, "id");
	return roundsByID[id];
});

export const getScheduledRoundId = createSelector(getScheduledRounds, (rounds) =>
	rounds.length ? rounds[0].id : 1
);

export const getActiveAndCompleteRounds = createSelector(getRounds, (rounds) =>
	filter(rounds, ({status}) => [RoundStatus.Active, RoundStatus.Complete].includes(status))
);

export const getIsFirstMatchComplete = createSelector(getRounds, (rounds) => {
	const firstMatchStatus = get(rounds, "[0].matches[0].status");
	return firstMatchStatus === MatchStatus.Complete;
});

export const getActiveOrCompleteRound = createSelector(
	getRounds,
	(rounds) =>
		findLast(rounds, ({status}) =>
			[RoundStatus.Active, RoundStatus.Complete].includes(status)
		) || first(rounds)
);

export const getActualRound = createSelector(
	getRounds,
	(rounds) =>
		rounds.find(({status}) => [RoundStatus.Active, RoundStatus.Scheduled].includes(status)) ||
		last(rounds)
);

export const getRoundByIdSelector = createSelector(
	getRounds,
	getActualRound,
	(rounds, actualRound) =>
		memoize((id: number = 0) => {
			return id ? find(rounds, matches({id})) : actualRound;
		})
);

export const getSelectedRound = (id: number) =>
	createSelector(getRounds, getActualRound, (rounds, actualRound) => {
		return id ? find(rounds, matches({id})) : actualRound;
	});
export const getSelectedRoundMatches = createSelector(
	getRoundByIdSelector,
	getSelectedRoundID,
	(roundsById, selectedRoundID) => {
		return get(roundsById(selectedRoundID), "matches", []);
	}
);

export const getSelectedRoundMatchesById = createSelector(getSelectedRoundMatches, (matches) => {
	return keyBy(matches, "id");
});

export const getSelectedMatchesInArray = createSelector(getSelectedRoundMatches, (matches) => {
	return matches.map((match) => ({
		match: match.id,
	}));
});

export const getSquadsById = createSelector(getSquads, (squads) =>
	memoize((id: number = 0) => find(squads, {id}))
);

export const getSquadsKeyedByID = createSelector(getSquads, (squads) => {
	return keyBy(squads, "id");
});

export const getLadderSortedObject = createSelector(getLadder, (ladder) => {
	return chain(ladder)
		.groupBy("conference")
		.mapValues((conference) => {
			const divisions = groupBy(conference, "division");
			return mapValues(divisions, (division) => {
				return orderBy(division, ["percentage", "points"], ["desc", "desc"]);
			});
		})
		.value();
});

export const getRoundStatsById = createSelector(getRoundStats, (round_stats) =>
	memoize((id: number) => find(round_stats, {match_id: id}))
);

export const getFirstRoundId = createSelector(getRounds, (rounds) => get(first(rounds), "id", 0));

export const getLastRoundId = createSelector(getRounds, (rounds) => get(last(rounds), "id", 0));

export const getMREC = createSelector(getMRECState, (mrec) =>
	mrec ? mrec.find((el) => el.type === "mrec") || DEFAULT_MREC : DEFAULT_MREC
);

export const getLeaderboard = createSelector(getMRECState, (mrec) =>
	mrec ? mrec.find((el) => el.type === "leaderboard") || DEFAULT_MREC : DEFAULT_MREC
);

export const getBBLNotificationBar = createSelector(getNotificationBarState, (notificationBar) => {
	return notificationBar
		? notificationBar.find((el) => el.sport === "bbl") || DEFAULT_NOTIFICATION
		: DEFAULT_NOTIFICATION;
});

export const getWBBLNotificationBar = createSelector(getNotificationBarState, (notificationBar) => {
	return notificationBar
		? notificationBar.find((el) => el.sport === "wbbl") || DEFAULT_NOTIFICATION
		: DEFAULT_NOTIFICATION;
});

export const getVenuesByID = createSelector(getVenuesState, (venues) => {
	return keyBy(venues, "id");
});

export const getFormGuideFromID = createSelector(getFormGuideState, (formguide) => (id: number) => {
	return formguide[id] || {};
});

// CONTROLS LOCKOUT
export const getIsOneMatchStartingOrLive = createSelector(getRounds, (rounds) => {
	const allMatchesLiveOrStarting = rounds.map((round) => {
		return get(round, "matches", []);
	});
	if (allMatchesLiveOrStarting.length === 0) {
		return false;
	}
	const flattenedMatches = allMatchesLiveOrStarting.flat().filter((match) => {
		const isPlaying = match.status === MatchStatus.Active;
		const isScheduled = match.status === MatchStatus.Scheduled;
		const startTime = moment.parseZone(match.date);
		const isStartedAndScheduled = startTime.diff(moment(), "minutes", true) <= 0 && isScheduled;
		return isPlaying || isStartedAndScheduled;
	});
	return flattenedMatches.length > 0;
});

export const getLadderBySquadID = createSelector(getLadder, (ladder) => {
	return keyBy(ladder, "squadId");
});

export const getStartEndCompDate = createSelector(getRounds, (rounds) => {
	const roundsEndIndex = rounds.length - 1;
	if (rounds.length === 0) {
		return {
			startDate: new Date(),
			endDate: new Date(),
		};
	}
	return {
		startDate: rounds[0].startDate,
		endDate: rounds[roundsEndIndex].endDate,
	};
});

export const getRoundsById = createSelector(getRounds, (rounds) => {
	return keyBy(rounds, "id");
});
