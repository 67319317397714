import React from "react";
import {PrimaryButton, HOCModal, WarningButton} from "components";
import styled from "styled-components";
import {IconWarning} from "components/Icons/IconWarning";
import {theme} from "assets/themes/bbl";
import {useBlockNavigation} from "modules/utils";

const WarningWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 20px auto;
	width: 50px;
	height: 50px;

	svg {
		height: 50px;
		width: 50px;
	}
`;

const ErrorModalContainer = styled.div`
	padding: 20px;
	background: #fff;
	color: #000;
	text-align: center;

	h1 {
		font-family: ${theme.font.montserrat}, sans-serif;
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0;
		line-height: 20px;
		text-align: center;
		margin-bottom: 20px;
		color: ${theme.colors.primary};
	}
	p {
		font-family: ${theme.font.montserrat}, sans-serif;
		font-size: 14px;
		font-weight: 400;
		letter-spacing: 0;
		line-height: 20px;
		text-align: center;
		margin-bottom: 20px;
	}
`;

const Buttons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	button {
		width: 45%;
		max-width: 200px;
		margin: 0 10px;
	}
`;

const Text = styled.p`
	margin-bottom: 20px;
`;

interface IProps {
	isBlocking: boolean;
}

export const HOCPromptModal: React.FC<IProps> = ({isBlocking, children}) => {
	const [showPrompt, confirmNavigation, cancelNavigation] = useBlockNavigation(isBlocking);
	if (!showPrompt) {
		return null;
	}
	return (
		<HOCModal onClose={cancelNavigation} isVisible={showPrompt}>
			<ErrorModalContainer>
				<WarningWrap>
					<IconWarning />
				</WarningWrap>
				<h1>Are you sure?</h1>
				<Text>{children}</Text>
				<Buttons>
					<PrimaryButton onClick={cancelNavigation}>No</PrimaryButton>
					<WarningButton onClick={confirmNavigation}>Yes</WarningButton>
				</Buttons>
			</ErrorModalContainer>
		</HOCModal>
	);
};
