import React, {Fragment} from "react";
import styled from "styled-components";
import {Header, AdvertRectangle, Exist} from "components";
import {useMediaQuery} from "modules/utils";

/**
 * need to check correct bgColor
 * f1f1f1 or fff
 * or both on different screens?
 */

const Main = styled.main`
	width: 100%;
	min-height: 100%;
	background: #fff;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	padding-bottom: 55px;
	&::-webkit-scrollbar {
		display: none;
	}
	@media (min-width: 640px) {
		padding-bottom: 0;
	}
`;

const InnerMain = styled.div`
	flex-grow: 1;
	line-height: 150%;

	@media screen and (max-width: 1220px) {
		line-height: 180%;
		padding: 0 20px;
	}

	@media (max-width: 639px) {
		position: relative;
		padding: 0 0px;
	}
`;

export const HOCMain: React.FC = ({children}) => {
	const isMobile = useMediaQuery("(max-width: 639px)");
	return (
		<Fragment>
			<Main>
				<Header />
				<AdvertRectangle id={"top"} />
				<InnerMain>{children}</InnerMain>
				<Exist when={isMobile}>
					<AdvertRectangle id={"top"} />
				</Exist>
			</Main>
		</Fragment>
	);
};
