import React, {useCallback} from "react";
import {PrimaryButton, HOCModal, WarningButton} from "components";
import {useDispatch, useSelector} from "react-redux";
import {getConfirmModalState} from "modules/selectors";
import {hideConfirmModal} from "modules/actions";
import styled from "styled-components";
import {IconWarning} from "components/Icons/IconWarning";
import {theme} from "assets/themes/bbl";

const WarningWrap = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 30px auto;
	width: 50px;
	height: 50px;
	svg {
		width: 50px;
		height: 45px;
	}
`;

const ErrorModalContainer = styled.div`
	padding: 20px;
	background: #fff;
	color: #000;
	text-align: center;
	border-radius: 5px;

	p,
	div {
		color: ${theme.colors.primary};
		font-family: ${theme.font.montserrat};
		font-size: 20px;
		font-weight: 600;
		letter-spacing: -0.1px;
		line-height: 24px;
		text-align: center;
		margin-bottom: 20px;
	}
`;

const Buttons = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	button {
		width: 45%;
		max-width: 200px;
		margin: 0 10px;
	}
`;

interface IProps {
	onConfirm: () => void;
}

export const HOCConfirmModal: React.FC<IProps> = ({onConfirm, children}) => {
	const dispatch = useDispatch();
	const modalState = useSelector(getConfirmModalState);

	const onClose = useCallback(() => {
		dispatch(hideConfirmModal());
	}, [dispatch]);

	if (!modalState) {
		return null;
	}

	return (
		<HOCModal onClose={onClose} isVisible={modalState}>
			<ErrorModalContainer>
				<WarningWrap>
					<IconWarning />
				</WarningWrap>
				<div>{children}</div>
				<Buttons>
					<PrimaryButton onClick={onClose}>No</PrimaryButton>
					<WarningButton onClick={onConfirm}>Yes</WarningButton>
				</Buttons>
			</ErrorModalContainer>
		</HOCModal>
	);
};
