import React, {useState} from "react";
import styled from "styled-components";
import {TippingRoundsSelector} from "./RoundsSelector";
import {IconGoldenWeek} from "components/Icons/IconGoldenWeek";
import {IconGoldWeekInfo} from "components/Icons/IconGoldWeekInfo";
import {IconBKT} from "components/Icons/BKT";
import {HOCModal, Exist} from "components";
import {theme} from "assets/themes/bbl";
import {useMediaQuery} from "modules/utils";

const Wrapper = styled.div<{
	isGoldenWeek: boolean;
}>`
	display: flex;
	justify-content: ${({isGoldenWeek}) => (isGoldenWeek ? "space-between" : "flex-start")};
	align-items: center;
	width: 100%;
	margin-bottom: 20px;
	.bkt-sponsor {
		min-width: 60px;
		min-height: 20px;
		margin-left: 10px;
		max-height: 40px;
	}
	${({isGoldenWeek}) =>
		isGoldenWeek &&
		`
		.round-value{
			padding: 0 2px;
		}
	`}
`;

const GoldenWeekWrapper = styled.button`
	display: flex;
	align-items: center;
	font-family: ${theme.font.montserrat};
	font-weight: 600;
	font-size: 14px;
	letter-spacing: -0.07px;
	color: ${theme.colors.primary};
	justify-self: flex-end;
	border: none;
	outline: none;
	background: none;
	svg {
		margin-left: 4px;
	}
	@media (max-width: 640px) {
		min-width: 168px;
	}
`;

const ModalContentWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	height: 300px;
	justify-content: space-evenly;
	padding: 45px 80px;
`;

const Heading = styled.h1`
	font-size: 20px;
	font-weight: 700;
	font-family: ${theme.font.montserrat};
	color: ${theme.colors.primary};
	letter-spacing: -0.1px;
	margin-bottom: 10px;
`;

const SubHeading = styled.p`
	font-size: 14px;
	font-weight: 400;
	font-family: ${theme.font.montserrat};
	color: ${theme.colors.darkGrey};
`;

const MakePickButton = styled.button`
	outline: none;
	border: none;
	color: #000000;
	font-size: 14px;
	font-family: ${theme.font.montserrat};
	font-weight: 600;
	background-color: ${theme.colors.gold};
	border-radius: 2px;
	width: 200px;
	height: 40px;
	margin-top: 40px;
`;

const CenterCol = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 20px;
`;

interface IModalProps {
	onClose: () => void;
	isVisible: boolean;
}

const GoldenWeekModal: React.FC<IModalProps> = ({onClose, isVisible}) => {
	return (
		<HOCModal onClose={onClose} isVisible={isVisible}>
			<ModalContentWrapper>
				<IconGoldenWeek />
				<CenterCol>
					<Heading>BKT Golden Week</Heading>
					<SubHeading>
						You'll earn double points this week, so make sure to get your tips in!
					</SubHeading>
				</CenterCol>

				<MakePickButton onClick={onClose}>Make Picks</MakePickButton>
			</ModalContentWrapper>
		</HOCModal>
	);
};

interface IProps {
	isGoldenWeek: boolean;
}

export const TippingTopControls: React.FC<IProps> = ({isGoldenWeek}) => {
	const [showModal, setShowModal] = useState(false);
	const isMobile = useMediaQuery("(max-width: 640px)");
	const showBKTLogo = isMobile && !isGoldenWeek;
	return (
		<Wrapper isGoldenWeek={isGoldenWeek}>
			<TippingRoundsSelector />
			{isGoldenWeek && (
				<GoldenWeekWrapper onClick={() => setShowModal(true)}>
					{isMobile ? "BKT Golden Week" : "What is BKT Golden Week?"}
					<IconGoldWeekInfo />
				</GoldenWeekWrapper>
			)}
			{showModal && (
				<GoldenWeekModal onClose={() => setShowModal(false)} isVisible={showModal} />
			)}
			<Exist when={showBKTLogo}>
				<IconBKT />
			</Exist>
		</Wrapper>
	);
};

export * from "./RoundsSelector";
