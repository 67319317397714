import React from "react";
import styled from "styled-components";
import {theme} from "assets/themes/bbl";
import {IconWarning} from "components/Icons/IconWarning";

const Wrapper = styled.div`
	width: 100%;
	height: 150px;
	background: white;
	border-radius: 5px;
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: space-evenly;
	svg {
		height: 40px;
		width: 40px;
	}
`;

const HoldingHeader = styled.h1`
	font-family: ${theme.font.halisa};
	font-size: 20px;
	font-weight: 600;
	line-height: 22px;
	color: ${theme.colors.primary};
	text-align: center;
`;

const HoldingMessage = styled.p`
	font-family: ${theme.font.montserrat};
	font-size: 14px;
	line-height: 16px;
	font-weight: 400;
	color: ${theme.colors.darkGrey};
	text-align: center;
`;

export const RankingsHolding: React.FC = () => {
	return (
		<Wrapper>
			<IconWarning />
			<HoldingHeader>Rankings Information</HoldingHeader>
			<HoldingMessage>
				Please note: Rankings will be available after the 1st match.
			</HoldingMessage>
		</Wrapper>
	);
};
