import {HOCModal} from "./index";
import React, {useCallback} from "react";
import styled from "styled-components";
import useClipboard from "react-use-clipboard";
import {
	FacebookButton,
	TwitterButton,
	IconFacebook,
	IconTwitter,
	CopyLinkButton,
	Exist,
} from "components";
import {IconShare} from "components/Icons/IconShare";
import {IconShareLink} from "components/Icons/IconShareLink";
import {useDispatch, useSelector} from "react-redux";
import {getShareModalState} from "modules/selectors";
import {hideShareModal} from "modules/actions";
import {share} from "modules/utils/Share";
import {partial} from "lodash";
import {IShareType, IShareSection} from "modules/types";
import {ILeague} from "modules/reducers/leagues";
import {useSportRoute, useMediaQuery} from "modules/utils";
import {theme} from "assets/themes/bbl";

const ShareModalContent = styled.div`
	padding: 34px 20px 20px;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const ShareModalIcon = styled.div`
	width: 125px;
	height: 50px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 25px;
	color: #fff;
	svg {
		height: 50px;
		width: auto;
		path {
			fill: #000;
		}
	}
	@media screen and (max-width: 640px) {
		width: 50px;
		justify-content: center;
		svg {
			height: 45px;
			width: 42.5px;
		}
	}
`;

const ShareModalText = styled.div`
	font-family: ${theme.font.montserrat}, sans-serif;
	font-size: 20px;
	line-height: 24px;
	color: ${theme.colors.primary};
	letter-spacing: -0.1px;
	font-weight: 600;
	text-align: center;
	margin-bottom: 25px;
`;

const ShareModalButtons = styled.div`
	width: 220px;
	display: flex;
	flex-direction: column;
	align-items: center;

	${FacebookButton},
	${TwitterButton} {
		width: 100%;
		margin: 10px 0 0;
		text-transform: uppercase;
	}
`;

interface IShareModalProps {
	section?: IShareSection;
	league_data?: Partial<ILeague>;
}

export const ShareModal: React.FC<IShareModalProps> = ({section = "general", league_data}) => {
	const dispatch = useDispatch();
	const isMobile = useMediaQuery("(max-width: 640px)");
	const modalState = useSelector(getShareModalState);
	const sport = useSportRoute();
	const sportName = sport.substring(1);
	const onCloseModal = useCallback(() => {
		dispatch(hideShareModal());
	}, [dispatch]);

	const leagueDataCode = league_data?.code ? league_data.code : "";
	const link = `${window.location.origin}/leagues/join/${leagueDataCode}`;

	const [is_link_copied, setLinkCopied] = useClipboard(link, {
		successDuration: 3000,
	});

	if (!modalState.isShow) {
		return null;
	}

	const shareClick = (type: IShareType) => share({sc: type, section, league_data, sportName});

	return (
		<HOCModal onClose={onCloseModal} isVisible={modalState.isShow}>
			<ShareModalContent>
				<ShareModalIcon>
					<Exist when={isMobile}>
						<IconShare />
					</Exist>
					<Exist when={!isMobile}>
						<IconFacebook />
						<IconTwitter />
					</Exist>
				</ShareModalIcon>

				{modalState.text && <ShareModalText>{modalState.text}</ShareModalText>}

				<ShareModalButtons>
					<FacebookButton onClick={partial(shareClick, "fb")}>
						Facebook <IconFacebook />
					</FacebookButton>
					<TwitterButton onClick={partial(shareClick, "tw")}>
						Twitter <IconTwitter />
					</TwitterButton>
					{section === "league" && (
						<CopyLinkButton onClick={setLinkCopied}>
							{is_link_copied ? (
								"Copied!"
							) : (
								<React.Fragment>
									Copy Link <IconShareLink color={"#fff"} />
								</React.Fragment>
							)}
						</CopyLinkButton>
					)}
				</ShareModalButtons>
			</ShareModalContent>
		</HOCModal>
	);
};
