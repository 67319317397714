import React from "react";
import moment from "moment";
import {isFunction, padStart} from "lodash";

const MS_IN_SECOND = 1000;
const SEC_IN_MINUTE = 60;
const MIN_IN_HOUR = 60;
const HRS_IN_DAY = 24;

const defaultTimerValue = {
	days: 0,
	hours: 0,
	minutes: 0,
	seconds: 0,
	diff: 0,
};

interface IProps {
	format?: string;
	onComplete?: () => void;
	onTick?: (time_to_end: number) => void;
	children?: (params: IState) => React.ReactNode | React.ReactChildren;
	date: number | string | Date;
	be_time_diff: number;
	correction: boolean;
	is_animated?: boolean;
}

export interface IState {
	days: number;
	hours: number;
	minutes: number;
	seconds: number;
	diff: number;
	completed: boolean;
}

export class HOCTimer extends React.Component<IProps, IState> {
	public static defaultProps = {
		format: "hh:mm:ss",
		correction: true,
		be_time_diff: 0,
	};

	public state = {
		...defaultTimerValue,
		completed: false,
	};

	private timer?: number;

	constructor(props: IProps) {
		super(props);

		this.state = {
			...this.state,
			...this.timerValues(this.duration),
		};
	}

	public render() {
		const {children, is_animated} = this.props;

		if (children) {
			return children(this.state);
		}

		return is_animated ? this.animated_timer : this.base_timer;
	}

	public componentDidMount() {
		if (this.duration <= 0) {
			return;
		}

		this.timer = window.setInterval(() => this.diff(), 333);
	}

	public componentWillUnmount() {
		if (this.timer) {
			window.clearInterval(this.timer);
		}
	}

	private get duration() {
		const {be_time_diff, correction} = this.props;
		const startDate = moment(Date.now() + (correction ? be_time_diff : 0));
		const endDate = moment(this.props.date);

		return endDate.diff(startDate);
	}

	private timerValues(diff: number) {
		if (diff < MS_IN_SECOND) {
			return defaultTimerValue;
		}

		const seconds = Math.floor(diff / MS_IN_SECOND);
		const minutes = Math.floor(seconds / SEC_IN_MINUTE);
		const hours = Math.floor(minutes / MIN_IN_HOUR);

		return {
			diff,
			days: Math.floor(hours / HRS_IN_DAY),
			hours: hours % HRS_IN_DAY,
			minutes: minutes % MIN_IN_HOUR,
			seconds: seconds % SEC_IN_MINUTE,
		};
	}

	private diff() {
		const diff = this.duration;
		const {onTick} = this.props;

		if (isFunction(onTick)) {
			onTick(diff);
		}

		if (diff < MS_IN_SECOND) {
			return this.handleCompleteState();
		}

		this.setState(this.timerValues(diff));
	}

	private handleCompleteState() {
		clearInterval(this.timer);

		const {onComplete} = this.props;

		this.setState({
			completed: true,
			...defaultTimerValue,
		});

		if (isFunction(onComplete)) {
			onComplete();
		}
	}

	private get base_timer() {
		const {diff, minutes, seconds} = this.state;

		if (!diff) {
			return "00:00";
		}

		return [minutes, seconds].map((unit) => padStart(String(unit), 2, "0")).join(":");
	}

	private get animated_timer() {
		const {diff, seconds} = this.state;

		if (!diff) {
			return "0";
		}

		return seconds;
	}
}
