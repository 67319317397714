import {IStore} from "modules/types";
import {createSelector} from "reselect";
import {getUser} from "modules/selectors";
import {memoize, find, chain} from "lodash";

export const getLeaguesState = (state: IStore) => state.leagues;

export const getLeague = (state: IStore) => state.leagues.league;
export const getLeagues = (state: IStore) => state.leagues.leagues;

export const getIsJoined = (state: IStore) => state.leagues.isJoined;

export const getLeagueById = createSelector(getLeagues, (leagues) =>
	memoize((id: number = 0) => find(leagues, {id}))
);

export const getLeaguesForJoin = (state: IStore) => state.leagues_for_join;
export const getJoinedUsers = ({leagues}: IStore) => leagues.joined_users;
export const getLeaguesLadderStore = (state: IStore) => state.league_ladder;
export const getLeaguesLadders = (state: IStore) => state.league_ladder.items;

export const getJoinedUsersNext = ({leagues}: IStore) => leagues.next_joined_users;

export const getLeagueLadderLoading = createSelector(
	getLeaguesLadderStore,
	(ladder) => ladder.is_data_requested
);

export const getLeagueLoadingStatus = createSelector(
	getLeaguesState,
	(leagues) => leagues.league_loading_status
);

export const getLeagueErrors = createSelector(getLeaguesState, (leagues) => leagues.errors);

export const getLeaguesLadderWinners = createSelector(getLeaguesLadders, (items) =>
	chain(items)
		.filter(({rank}) => rank >= 1 && rank <= 3)
		.sortBy("rank")
		.take(3)
		.value()
);

export const isLeagueCommissioner = createSelector(
	getUser,
	getLeague,
	(user, {userId}) => user?.id === userId
);

export const getSendEmailState = (state: IStore) => state.leagues.send_email_process;
export const isPageBlurred = (state: IStore) => state.leagues.blurPage;
