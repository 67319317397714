import React from "react";
import {Navigate, Outlet, RouteProps} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getUser, isBBLUser, isLoggedIn, isWBBLUser} from "modules/selectors";
import {allTrue, getSportName} from "modules/utils/helpers";
import {get} from "lodash";
import moment from "moment";
import {showBonusPointModal} from "modules/actions";
import {Storage} from "modules/utils";

// const COMPARE_DATE = "2022-02-24";
const COMPARE_DATE = "2023-02-24";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
	const dispatch = useDispatch();
	const isLogged = useSelector(isLoggedIn);
	const userObject = useSelector(getUser);
	const isUserWBBL = useSelector(isWBBLUser);
	const isUserBBL = useSelector(isBBLUser);
	const sportName = getSportName();
	const isRegisteredForSport = sportName === "bbl" ? isUserBBL : isUserWBBL;
	const recoveredSlug = `recovered`;
	const isRecovered = Boolean(get(userObject, recoveredSlug, 0));

	const registeredDate = String(get(userObject, "registeredAt.date"));
	const isNewUser = moment(COMPARE_DATE).isBefore(registeredDate);

	if (isLogged) {
		const showedBonusPointModal = Storage.GET(`showedBonusPointModal_${userObject.id}`);
		!showedBonusPointModal && dispatch(showBonusPointModal());
		Storage.SET(`showedBonusPointModal_${userObject.id}`, true);
	}

	if (allTrue([isRegisteredForSport, !isRecovered, isLogged, !isNewUser])) {
		return <Navigate to={`/${sportName}`} replace />;
	}

	if (isLogged) {
		return <Outlet />;
	}

	return <Navigate to={"/"} replace />;
};
