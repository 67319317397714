import pkceChallenge from "pkce-challenge";
import {v4 as uuidv4} from "uuid";
import {Storage} from "modules/utils";
import {
	REACT_APP_SSO_URL,
	REACT_APP_SSO_API_KEY,
	REACT_APP_SSO_CLIENT_ID,
	REACT_APP_SSO_REDIRECT_URI,
	REACT_APP_SSO_API_KEY_CA_LIVE_APP,
	REACT_APP_SSO_CLIENT_ID_CA_LIVE_APP,
	REACT_APP_SSO_REDIRECT_URI_CA_LIVE_APP,
} from "modules/utils/constants";

export * from "./hooks";
export * from "./User";
export * from "./Storage";
export * from "./Api";
export * from "./constants";
export * from "./enums";

const {code_verifier, code_challenge} = pkceChallenge(128);

export const isAppUser = window.location.pathname.includes("login-app");

export const getLoginLink = () => {
	const nonce: string = uuidv4();
	const myState: string = uuidv4();
	Storage.SET("code_verifier", code_verifier);
	Storage.SET("code_challenge", code_challenge);
	Storage.SET("nonce", nonce);
	Storage.SET("myState", myState);

	const keyToUse = isAppUser ? REACT_APP_SSO_API_KEY_CA_LIVE_APP : REACT_APP_SSO_API_KEY;
	const clientIdToUse = isAppUser ? REACT_APP_SSO_CLIENT_ID_CA_LIVE_APP : REACT_APP_SSO_CLIENT_ID;
	const redirectUriToUse = isAppUser
		? REACT_APP_SSO_REDIRECT_URI_CA_LIVE_APP
		: REACT_APP_SSO_REDIRECT_URI;
	const params = [
		`client_id=${clientIdToUse}`,
		`redirect_uri=${redirectUriToUse}`,
		"grant_type=authorization_code",
		`code_challenge=${code_challenge}`,
		`state=${myState}`,
		`nonce=${nonce}`,
		"code_challenge_method=S256",
		"response_type=code",
		"scope=openid email firstname lastname favteam",
	];
	return `${REACT_APP_SSO_URL}/${keyToUse}/authorize?${params.join("&")}`;
};
