import React, {useState} from "react";
import {useSelector} from "react-redux";
import styled from "styled-components";
import {NavLink, Link} from "react-router-dom";
import {getSportName} from "modules/utils/helpers";
import bblSponsor from "assets/img/m_header_logo.svg";
import wbblSponsor from "assets/img/w_header_logo.svg";
import {media} from "assets/css/media";
import {Exist, IconArrowSlim} from "components";
import {IconExternalLink} from "components/Icons/IconExternalLink";
import {IconBurger} from "components/Icons/IconBurger";
import {IconCross} from "components/Icons/IconCross";
import {isBBLUser, isWBBLUser} from "modules/selectors";
import {useMediaQuery} from "modules/utils/hooks";
import {theme} from "assets/themes/bbl";
import {isWBBL} from "modules/utils";

const YOUR_LEAGUE_TITLE = "Your Leagues";

export const SubNav = styled.ul<{
	isShow: boolean;
}>`
	display: ${({isShow}) => (isShow ? "block" : "none")};
	font-size: 14px;
	z-index: 100;
	position: absolute;
	top: 90px;
	li {
		padding-bottom: 0;
		line-height: 14px;
	}
`;

export const SubNavWrap = styled.div<{
	isShow: boolean;
	isWBBL: boolean;
}>`
	display: ${({isShow}) => (isShow ? "block" : "none")};
	background: linear-gradient(
		90deg,
		rgba(50, 53, 216, 255) 0%,
		rgba(94, 39, 170, 255) 25%,
		rgba(158, 20, 106, 255) 75%,
		rgba(208, 4, 53, 255) 100%
	);
	${({isWBBL}) =>
		isWBBL &&
		`
		background: linear-gradient(
			90deg,
			rgba(165,25,190,255) 0%,
			rgba(185,45,160,255) 25%,
			rgba(220,77,109,255) 75%,
			rgba(247,102,67,255) 100%
		);
	`}
	background-size: cover;
	height: 120px;
	position: absolute;
	left: 0px;
	width: 100vw;
	top: 70px;
	z-index: 1;
`;

const getNavItems = (sport: string) => {
	const otherGame = sport === "bbl" ? "wbbl" : "bbl";
	const otherGameUpperCase = otherGame.toUpperCase();
	return [
		{
			name: "Tips",
			path: `/${sport}/tipping`,
		},
		{
			name: YOUR_LEAGUE_TITLE,
			path: `/${sport}/leagues`,
		},
		{
			name: "Rankings",
			path: `/${sport}/rankings`,
		},
		{
			name: "Help",
			path: `/${sport}/help`,
		},
		{
			name: "Prizes",
			path: `/${sport}/help/prizes`,
		},
		{
			name: `${otherGameUpperCase} Tipping`,
			path: `/${otherGame}`,
			external: true,
		},
	];
};

const Wrapper = styled.div`
	display: flex;
	position: relative;
	align-items: center;
	width: 100%;
	max-width: 1200px;
	min-height: 120px;
	margin: 0 auto;
	padding: 25px 0px;
	.sponsor-logo-web {
		width: 228px;
		height: auto;
		margin-right: 25px;
		z-index: 2;
	}
	.top-logo-mob {
		width: 96px;
		height: auto;
		position: absolute;
		z-index: 20;
		top: -20px;
		left: calc(50% - 48px);
	}
	.sponsor-logo-mob {
		width: 156px;
		height: auto;
		position: absolute;
		z-index: 20;
		top: 13px;
		left: calc(50% - 70px);
	}
	@media screen and (max-width: 639px) {
		position: sticky;
		top: 0;
		padding: 0px;
		min-height: unset;
		z-index: 10;
	}
`;

const MainNavItemWrapper = styled.div`
	display: flex;
`;

const NavigationWrap = styled.ul`
	display: flex;
	justify-content: space-between;
	width: 100%;

	li {
		> a {
			border-bottom: 1px solid transparent;
		}
	}
	li:hover {
		> a {
			color: #fff;
			border-bottom: 1px solid #fff;
			a {
				border-bottom: none;
			}
		}
		/* ${SubNavWrap} {
			display: block;
		} */
		${SubNav} {
			display: block;
			z-index: 100;
			position: absolute;
			top: 90px;
			li {
				padding-bottom: 0;
				line-height: 14px;
			}
		}
	}
	@media screen and (max-width: ${media.mobile}) {
		flex-direction: column;
		padding-left: 0px;
		min-height: 60px;
		justify-content: center;
		li:hover {
			> a {
				color: #fff;
				border-bottom: none;
				a {
					border-bottom: none;
				}
			}
		}
	}
`;

const NavItemLi = styled.li<{
	reverse?: boolean;
}>`
	margin: auto 15px;
	display: flex;
	& a.active {
		border-bottom: 1px solid white;
	}
	svg {
		margin-right: 6px;
		height: 15px;
		margin-top: 2px;
		width: 15px;
	}
	@media screen and (max-width: ${media.mobile}) {
		align-items: center;
		margin: 0px 15px;
		width: 100%;
		min-height: 60px;
		border-bottom: 1px solid white;
		padding: 0 20px;
		${({reverse}) =>
			reverse &&
			`
			flex-direction: row-reverse;
			justify-content: space-between;
		`}
		svg {
			margin-bottom: 8px;
			margin-top: 0px;
			height: 15px;
			width: 15px;
		}
		& a.active {
			border-bottom: none;
		}
	}
	& a,
	button {
		color: white;
		font-family: "Halisa";
		font-weight: 700;
		font-size: 16px;
		letter-spacing: 0;
		line-height: 20px;
		padding-bottom: 10px;
		text-align: center;
		text-decoration: none;
		@media screen and (max-width: ${media.mobile}) {
			line-height: 25px;
			width: 100%;
			text-align: left;
		}
	}
	&.sub-nav {
		font-weight: 400;
	}
`;

const LogoutNavItem = styled(NavItemLi)`
	background: black;
	width: 100%;
	position: fixed;
	bottom: -2px;
	left: -15px;
	margin-bottom: 0px;
	padding-left: 0px;
	padding-right: 0px;
	a {
		padding-left: 20px;
	}
`;

const SubNavItem = styled(NavItemLi)`
	margin: 0;
	a {
		font-family: ${theme.font.montserrat};
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		padding-bottom: 0px;
		margin-bottom: 10px;
	}
`;

const HamburgerButton = styled.button`
	outline: none;
	border: none;
	background: transparent;
	min-height: 28px;
	margin-left: 10px;
	min-width: 28px;
	width: 28px;
	z-index: 20;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s;
	svg {
		min-height: 20px;
		min-width: 20px;
		path {
			fill: white;
		}
	}
`;

const HamburgerMenuWrapper = styled.div`
	position: absolute;
	top: 0px;
	left: 0;
	z-index: 10;
	padding-top: 59px;
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	background: linear-gradient(
		225deg,
		rgba(254, 87, 34, 1) 0%,
		rgba(228, 0, 43, 1) 25%,
		rgba(131, 33, 225, 1) 75%,
		rgba(28, 67, 255, 1) 100%
	);
	background-size: cover;
`;

const NavigationItemDrop = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	width: 100%;
	min-height: 60px;
	img {
		width: 15px;
		height: 15px;
	}
`;

const DropDownButton = styled.button<{
	isOpen: boolean;
}>`
	width: 25px;
	height: 25px;
	outline: none;
	transform: rotate("90deg");
	border: none;
	background: none;
	${({isOpen}) =>
		isOpen &&
		`
		transform: rotate(180deg);
	`}
	transition: all 0.3s ease-out;
`;

const NavigationItemContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: self-start;
	width: 100%;
	padding: 0 20px;
	border-bottom: 1px solid white;
	& a,
	button {
		color: white;
		font-family: "Halisa";
		font-weight: 700;
		font-size: 16px;
		letter-spacing: 0;
		line-height: 20px;
		padding-bottom: 10px;
		text-align: center;
		text-decoration: none;
		@media screen and (max-width: ${media.mobile}) {
			line-height: 25px;
		}
		&.sub-nav {
			font-weight: 400;
			height: 50px;
			width: 100%;
			text-align: left;
		}
	}
	& a.active {
		border-bottom: 1px solid white;
	}
`;

interface INavLinkToggle {
	itemKey: string;
	path: string;
	label: string;
}

interface IProps {
	showSub: boolean;
	toggleSubMenuOn: () => void;
	toggleSubMenuOff: () => void;
}

export const Navigation: React.FC<IProps> = ({showSub, toggleSubMenuOn, toggleSubMenuOff}) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const [leaguesOpen, setLeaguesMenu] = useState(false);
	const isLoggedBBL = useSelector(isBBLUser);
	const isLoggedWBBL = useSelector(isWBBLUser);
	const currentSport = getSportName();
	const logoToDisplay = currentSport === "bbl" ? bblSponsor : wbblSponsor;
	const navItems = getNavItems(currentSport);
	const currentURL = window.location.href;
	const showWBBL = isWBBL(currentURL);

	const isMobile = useMediaQuery("(max-width: 639px)");

	const isBurgerMenuOpen = isMobile && menuOpen;

	const getExternalPath = () => {
		const isUserAllowed = currentSport === "bbl" ? isLoggedWBBL : isLoggedBBL;
		const externalCode = currentSport === "bbl" ? "wbbl" : "bbl";
		if (isUserAllowed) {
			return `/${externalCode}/tipping`;
		}
		return `/${externalCode}`;
	};

	const handleMenuToggle = () => {
		setMenuOpen(!menuOpen);
	};

	const toggleLeaguesMenu = () => {
		setLeaguesMenu(!leaguesOpen);
	};

	const NavLinkToggle = ({itemKey, path, label}: INavLinkToggle) => (
		<NavLink key={itemKey} to={path}>
			<div>{label}</div>
		</NavLink>
	);

	const SubNavLinkToggle = ({itemKey, path, label}: INavLinkToggle) => (
		<NavLink key={itemKey} to={path} className="sub-nav">
			{label}
		</NavLink>
	);

	const myLeaguesItems = [
		<SubNavLinkToggle
			key="join-league"
			itemKey="join-league"
			path={`/${currentSport}/leagues/join`}
			label="Join a league"
		/>,
		<SubNavLinkToggle
			key="create-league"
			itemKey="create-league"
			path={`/${currentSport}/leagues/create`}
			label="Create a league"
		/>,
	];

	const mobileMyLeagues = () => {
		return (
			<NavigationItemContainer>
				<NavigationItemDrop>
					<NavLinkToggle
						key="leagues-mob"
						itemKey="leagues-mob"
						path={`/${currentSport}/leagues`}
						label="Your Leagues"
					/>
					<DropDownButton onClick={toggleLeaguesMenu} isOpen={leaguesOpen}>
						<IconArrowSlim color={"white"} to={"bottom"} />
					</DropDownButton>
				</NavigationItemDrop>
				{leaguesOpen &&
					myLeaguesItems.map((item, index) => (
						<React.Fragment key={index}>{item}</React.Fragment>
					))}
			</NavigationItemContainer>
		);
	};

	const NavItems = () => {
		return (
			<React.Fragment>
				{/* eslint-disable-next-line complexity */}
				{navItems.map((item, index) => {
					const isItemExternal = item.external ? true : false;
					const path = getExternalPath();
					if (isItemExternal) {
						return (
							<NavItemLi
								key={index}
								reverse={isMobile}
								onMouseOver={toggleSubMenuOff}>
								<IconExternalLink color={"white"} />
								<Link to={path} target="_blank" rel="noreferrer">
									{item.name}
								</Link>
							</NavItemLi>
						);
					}
					if (isMobile && item.name === YOUR_LEAGUE_TITLE) {
						return mobileMyLeagues();
					}
					if (item.name === YOUR_LEAGUE_TITLE) {
						return (
							<NavItemLi
								key={index}
								className="leagues-item"
								onMouseOver={toggleSubMenuOn}>
								<NavLinkToggle
									itemKey={`${item.name}-${index}`}
									path={item.path}
									label={item.name}
								/>
								<SubNav
									className="league-sub-nav"
									isShow={showSub}
									onMouseOver={toggleSubMenuOn}>
									{myLeaguesItems.map((item, index) => (
										<SubNavItem key={index}> {item}</SubNavItem>
									))}
								</SubNav>
							</NavItemLi>
						);
					}
					return (
						<NavItemLi key={index} onMouseOver={toggleSubMenuOff}>
							{isItemExternal && <IconExternalLink color={"white"} />}
							<NavLinkToggle
								itemKey={`${item.name}-${index}`}
								path={item.path}
								label={item.name}
							/>
						</NavItemLi>
					);
				})}
			</React.Fragment>
		);
	};

	return (
		<React.Fragment>
			<Wrapper>
				<Exist when={isMobile}>
					<img src={logoToDisplay} alt="Sponsor Logo" className="sponsor-logo-mob" />
				</Exist>

				<NavigationWrap>
					<Exist when={!isMobile}>
						<MainNavItemWrapper>
							<Exist when={!isMobile}>
								<img
									src={logoToDisplay}
									alt="Sponsor Logo"
									className="sponsor-logo-web"
								/>
							</Exist>
							<NavItems />
						</MainNavItemWrapper>
						<NavItemLi key={"account"}>
							<NavLinkToggle
								itemKey={`account-toggle`}
								path={`/${currentSport}/account`}
								label={"Account"}
							/>
						</NavItemLi>
					</Exist>
					<Exist when={isMobile}>
						<HamburgerButton aria-label="View Menu" onClick={handleMenuToggle}>
							{menuOpen ? <IconCross /> : <IconBurger />}
						</HamburgerButton>
						{/* {menuOpen && (
							<img src={logoToDisplay} alt="Sponsor Logo" className="top-logo-mob" />
						)} */}
					</Exist>
					<Exist when={isBurgerMenuOpen}>
						<HamburgerMenuWrapper>
							<NavItems />
							<NavItemLi key={"account"}>
								<NavLinkToggle
									itemKey={`account-toggle`}
									path={`/${currentSport}/account`}
									label={"Account"}
								/>
							</NavItemLi>

							<LogoutNavItem>
								<NavLinkToggle
									itemKey={`logout-item`}
									path={"/logout"}
									label={"Logout"}
								/>
							</LogoutNavItem>
						</HamburgerMenuWrapper>
					</Exist>
				</NavigationWrap>
			</Wrapper>
			<SubNavWrap isShow={showSub} isWBBL={showWBBL} onMouseOver={toggleSubMenuOn} />
		</React.Fragment>
	);
};
