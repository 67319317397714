import React from "react";
import {Navigate, RouteProps, Outlet} from "react-router-dom";
import {useSelector} from "react-redux";
import {isLoggedIn, isWBBLUser, isBBLUser, getUser} from "modules/selectors";
import {allTrue, getFirstPageLink, getSportName, removeFirstPageLink} from "modules/utils/helpers";
import {get} from "lodash";
import moment from "moment";

// SWITCHED TO OFFSEASON (REGO CLOSED)
// 24/02/2022
// const compareDate = "2022-02-24";
const compareDate = "2023-02-24";

export const NotAuthOnlyRoute: React.FC<RouteProps> = (props) => {
	/**
	 * TODO - get this to work for both games
	 */
	const isUserLoggedIn = useSelector(isLoggedIn);
	const userObject = useSelector(getUser);
	const isUserWBBL = useSelector(isWBBLUser);
	const isUserBBL = useSelector(isBBLUser);
	const sportName = getSportName();
	const recoveredSlug = `recovered`;
	const isRecovered = Boolean(get(userObject, recoveredSlug, 0));
	const registeredDate = String(get(userObject, "registeredAt.date"));
	const isNewUser = moment(compareDate).isBefore(registeredDate);
	const firstPageLink = getFirstPageLink();
	const hasFirstPageLink = () => {
		if (firstPageLink === "createLink") {
			removeFirstPageLink();
			window.location.href = `/${sportName}/leagues/create`;
		}
		if (firstPageLink === "joinLink") {
			removeFirstPageLink();
			window.location.href = `/${sportName}/leagues/join`;
		}
		if (firstPageLink === "tippingLink") {
			removeFirstPageLink();
			window.location.href = `/${sportName}/tipping`;
		}
	};

	if (allTrue([isUserLoggedIn, isUserBBL, sportName === "bbl", isRecovered, !isNewUser])) {
		return <Navigate to="/bbl/tipping" replace />;
	}
	if (allTrue([isUserLoggedIn, isUserBBL, sportName === "bbl", !isRecovered, isNewUser])) {
		hasFirstPageLink();
		return <Navigate to="/bbl/tipping" replace />;
	}
	if (allTrue([isUserLoggedIn, isUserWBBL, sportName === "wbbl", isRecovered, !isNewUser])) {
		return <Navigate to="/wbbl/tipping" replace />;
	}
	if (allTrue([isUserLoggedIn, isUserWBBL, sportName === "wbbl", !isRecovered, isNewUser])) {
		hasFirstPageLink();
		return <Navigate to="/wbbl/tipping" replace />;
	}

	return <Outlet />;
};
