import React from "react";
import styled from "styled-components";
import {get} from "lodash";
import moment from "moment";
import {theme} from "assets/themes/bbl";
import {ISquad} from "modules/actions";
import {getTeamLogoUrl} from "modules/utils/constants";
import {IconCross} from "components/Icons/IconCross";
import {IconCheck} from "components/Icons/IconCheck";

const Wrapper = styled.div`
	height: 73px;
	width: 94px;
	display: flex;
	flex-direction: column;
	margin-right: 6px;
	background: white;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.11);
	border-radius: 4px;
`;

const InnerContentWrap = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	align-items: center;
	font-family: ${theme.font.montserrat};
	font-weight: 400;
	font-size: 10px;
	line-height: 13px;
	color: ${theme.colors.primary};
`;

const DateHeader = styled.p`
	font-family: ${theme.font.montserrat};
	font-weight: 400;
	font-size: 8px;
	line-height: 10px;
	color: ${theme.colors.primary};
	width: 100%;
	text-align: center;
	margin-top: 4px;
`;

const TeamBlock = styled.div<{
	isTipped: boolean;
	isCorrect: boolean;
}>`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 2px;
	img {
		height: 28px;
		width: 30px;
		margin-bottom: 4px;
	}
	font-family: ${theme.font.montserrat};
	font-weight: 700;
	line-height: 13px;
	font-size: 10px;
	color: #000;
	text-transform: uppercase;
	position: relative;
	${({isTipped, isCorrect}) =>
		isTipped &&
		isCorrect &&
		`
		border: 2px solid #339B0E;
		border-radius: 3px;
	`};
	${({isTipped}) =>
		!isTipped &&
		`
		opacity: 0.35;
	`};
	${({isTipped, isCorrect}) =>
		isTipped &&
		!isCorrect &&
		`
		border: 2px solid #F63A25;
		border-radius: 3px;
	`}
`;

const ResultIcon = styled.div<{
	isCorrect: boolean;
	isTipped: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	right: -4px;
	z-index: 1;
	bottom: 10px;
	height: 6px;
	width: 6px;
	${({isTipped, isCorrect}) =>
		isTipped &&
		isCorrect &&
		`
		background: #339B0E;
	`};
	${({isTipped, isCorrect}) =>
		isTipped &&
		!isCorrect &&
		`
		background: #F63A25;
	`}
	border-radius: 50%;
	svg {
		height: 2px;
		width: 2px;
		path {
			fill: #fff;
		}
	}
`;

interface ITipItem {
	homeSquad: ISquad | undefined;
	awaySquad: ISquad | undefined;
	tippedSquad: number;
	date: string;
	isCorrect: boolean | null;
}

export const TippingItem: React.FC<ITipItem> = ({
	homeSquad,
	awaySquad,
	tippedSquad,
	isCorrect,
	date,
}) => {
	const isTippedHome = tippedSquad === homeSquad?.id;
	const isHomeCorrect = isTippedHome && isCorrect === true;
	const isAwayCorrect = !isTippedHome && isCorrect === true;
	const awayShort = get(awaySquad, "shortName", "");
	const homeShort = get(homeSquad, "shortName", "");
	const awayLogo = getTeamLogoUrl(awayShort);
	const homeLogo = getTeamLogoUrl(homeShort);
	const dateFormat = moment.parseZone(date).format("ddd DD / MM");

	const handleIcon = (isTipped: boolean, isCorrect: boolean) => {
		if (isTipped && isCorrect) {
			return <IconCheck />;
		}
		if (isTipped && !isCorrect) {
			return <IconCross />;
		}
		return null;
	};

	return (
		<Wrapper>
			<DateHeader>{dateFormat}</DateHeader>
			<InnerContentWrap>
				<TeamBlock isTipped={isTippedHome} isCorrect={isHomeCorrect}>
					<img src={homeLogo} alt="Home Logo" />
					{homeSquad?.shortName}
					<ResultIcon isTipped={isTippedHome} isCorrect={isHomeCorrect}>
						{handleIcon(isTippedHome, isHomeCorrect)}
					</ResultIcon>
				</TeamBlock>
				v
				<TeamBlock isTipped={!isTippedHome} isCorrect={isAwayCorrect}>
					<img src={awayLogo} alt="Away Logo" />
					{awaySquad?.shortName}
					<ResultIcon isTipped={!isTippedHome} isCorrect={isAwayCorrect}>
						{handleIcon(!isTippedHome, isAwayCorrect)}
					</ResultIcon>
				</TeamBlock>
			</InnerContentWrap>
		</Wrapper>
	);
};
