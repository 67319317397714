import {Storage} from "modules/utils/Storage";

export const BASE_URL = process.env.REACT_APP_BASE_URL || "";
export const API_URL = process.env.REACT_APP_API_URL || "";
export const JSON_URL = process.env.REACT_APP_JSON_URL || "";
export const IS_API_ON_THE_SAME_HOST = API_URL.includes(window.location.host);
export const IMAGES_URL = process.env.REACT_APP_IMAGES_URL || "";
export const LOGIN_DISABLED = "loginEnabled";
export const getTeamLogoUrl = (shortName: string) => {
	return "https:" + IMAGES_URL + `logo/${shortName}.svg`;
};
export const REACT_APP_SSO_URL = process.env.REACT_APP_SSO_URL || "";
export const REACT_APP_SSO_API_KEY = process.env.REACT_APP_SSO_API_KEY || "";
export const REACT_APP_SSO_CLIENT_ID = process.env.REACT_APP_SSO_CLIENT_ID || "";
export const REACT_APP_SSO_REDIRECT_URI = process.env.REACT_APP_SSO_REDIRECT_URI || "";
export const REACT_APP_SSO_API_KEY_CA_LIVE_APP =
	process.env.REACT_APP_SSO_API_KEY_CA_LIVE_APP || "";
export const REACT_APP_SSO_CLIENT_ID_CA_LIVE_APP =
	process.env.REACT_APP_SSO_CLIENT_ID_CA_LIVE_APP || "";
export const REACT_APP_SSO_REDIRECT_URI_CA_LIVE_APP =
	process.env.REACT_APP_SSO_REDIRECT_URI_CA_LIVE_APP || "";
export const REACT_APP_LOGOUT_SSO_LINK = process.env.REACT_APP_LOGOUT_SSO_LINK || "";
export const REACT_APP_SITE_TITLE = process.env.REACT_APP_SITE_TITLE || "";
export const REACT_APP_ACCOUNT_URL = process.env.REACT_APP_ACCOUNT_URL || "";
export const REACT_APP_RETURN_URL = process.env.REACT_APP_RETURN_URL || "";

export const ROLE_USER = "ROLE_USER";
export const ROLE_USER_BBL = "ROLE_USER_BBL";
export const ROLE_USER_WBBL = "ROLE_USER_WBBL";

export const SPORT_BBL = "bbl";
export const SPORT_WBBL = "wbbl";

export const REACT_APP_WBBL_COMPNAME = process.env.REACT_APP_WBBL_COMPNAME || "";
export const REACT_APP_BBL_COMPNAME = process.env.REACT_APP_BBL_COMPNAME || "";

export const IS_SECRET = JSON.parse(
	Storage.GET(LOGIN_DISABLED) || process.env.REACT_APP_IS_SECRET || "false"
);
export const IS_SHOW_TRANSLATE_SELECT = JSON.parse(
	process.env.REACT_APP_SHOW_TRANSLATION_SELECT || "true"
);

export const MAIN_SITE_URL = process.env.REACT_APP_MAIN_SITE_URL || "";

export const BBL_TIPPING_MAIN_URL = MAIN_SITE_URL + "/bbl/tipping";
export const WBBL_TIPPING_MAIN_URL = MAIN_SITE_URL + "/wbbl/tipping";
export const IS_OFF_SEASON = process.env.REACT_APP_IS_OFFSEASON === "true";

// TO DO:
// Change these back to correct endpoints
// Changed to mlb for UI testing

export const SPORTS = {
	wbbl: {
		JSON: "wbbl/",
		IS_FROM_GUIDE: true,
	},
	bbl: {
		JSON: "bbl/",
		IS_FROM_GUIDE: false,
	},
	defaults: {
		JSON: "wbbl/",
		IS_FROM_GUIDE: true,
	},
};

export const LADDER_WIDGET_COLUMNS = {
	nfl: {
		headings: ["W", "L", "T", "%"],
		valueKeys: ["win", "loss", "draw", "percentage"],
	},
	nba: {
		headings: ["W", "L", "%"],
		valueKeys: ["win", "loss", "percentage"],
	},
	mlb: {
		headings: ["W", "L", "%"],
		valueKeys: ["win", "loss", "percentage"],
	},
	nhl: {
		headings: ["W", "L", "%"],
		valueKeys: ["win", "loss", "percentage"],
	},
};
export const DISABLED_GAMES = process.env.REACT_APP_DISABLED_GAMES || "";
export const DISABLED_GAMES_ARRAY = DISABLED_GAMES.split(",");
export const SKIP_GEO_CHECK = process.env.REACT_APP_SKIP_GEO_CHECK || "false";

export const WBBL_GOLDEN_WEEK = process.env.WBBL_GOLDEN_WEEK || 3;
export const BBL_GOLDEN_WEEK = process.env.BBL_GOLDEN_WEEK || 3;

export const IS_SECRET_PASSED = Boolean(localStorage.getItem("isSecretPassed") === "true");

export const isWBBL = (URL: string): boolean => {
	return URL.includes("wbbl");
};
