import "assets/css/reset.css";
import "assets/css/fonts.css";
import "assets/css/core.css";
import React, {lazy, StrictMode, Suspense} from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import {store} from "modules/store";
import {Provider} from "react-redux";
import * as Sentry from "@sentry/react";
import {
	BASE_URL,
	EnumLandingPages,
	IS_OFF_SEASON,
	IS_SECRET_PASSED,
	SportType,
} from "modules/utils";
import {Preloader} from "components/Preloader";
import {
	HOCSession,
	NotAuthOnlyRoute,
	PrivateRoute,
	Alert,
	Footer,
	HOCSportSelection,
	NotificationBar,
	ErrorModal,
	BonusPointModal,
	ShareModal,
} from "components";
import {retryFailLoad} from "modules/utils/lazy_load";
import {Integrations} from "@sentry/tracing";
import RedirectToHome from "components/RedirectToHome";

const Landing = lazy(retryFailLoad(() => import("pages/Landing")));
const Tipping = lazy(retryFailLoad(() => import("pages/Tipping")));
const Leagues = lazy(retryFailLoad(() => import("pages/Leagues")));
const League = lazy(retryFailLoad(() => import("pages/League")));
const Rankings = lazy(retryFailLoad(() => import("pages/Rankings")));
const LeaguesJoin = lazy(retryFailLoad(() => import("pages/JoinLeagues")));
const LeagueCreate = lazy(retryFailLoad(() => import("pages/CreateLeague")));
const Logout = lazy(retryFailLoad(() => import("pages/Logout")));
const NotFound = lazy(retryFailLoad(() => import("pages/NotFound")));
const Help = lazy(retryFailLoad(() => import("pages/Help")));
const Account = lazy(retryFailLoad(() => import("pages/Account")));

const PrivacyPolicy = lazy(retryFailLoad(() => import("pages/help/PrivacyPolicy")));
const Terms = lazy(retryFailLoad(() => import("pages/help/Terms")));
const Offseason = lazy(retryFailLoad(() => import("pages/Offseason")));

Sentry.init({
	dsn: "https://9652f3eb1e7e41249225ecfb39f9d477@o151969.ingest.sentry.io/5938495",
	environment: process.env.REACT_APP_SENTRY_ENV || "development",
	sampleRate: 0.1,
	allowUrls: ["tipping.cricket.com.au", ".fanhubmedia.com"],
	integrations: [
		new Integrations.BrowserTracing(),
		new Sentry.Integrations.Breadcrumbs({
			console: false,
		}),
	],
	denyUrls: [
		"quantcast",
		"xsca",
		// browser's extensions
		/extensions\//i,
		/^chrome:\/\//i,
		/^moz-extension:\/\//i,
	],
	autoSessionTracking: true,
});

const Login: React.FC = () => <Landing page={EnumLandingPages.Login} />;
const Registration: React.FC = () => <Landing page={EnumLandingPages.Registration} />;
const ForgotPassword: React.FC = () => <Landing page={EnumLandingPages.ForgotPassword} />;
const LandingBBL: React.FC = () => <Landing page={EnumLandingPages.BBL} />;
const LandingWBBL: React.FC = () => <Landing page={EnumLandingPages.WBBL} />;
const TippingBBL: React.FC = () => <Tipping sport={SportType.BBL} />;
const TippingWBBL: React.FC = () => <Tipping sport={SportType.WBBL} />;
const LeaguesBBL: React.FC = () => <Leagues sport={SportType.BBL} />;
const LeaguesWBBL: React.FC = () => <Leagues sport={SportType.WBBL} />;
const RankingsBBL: React.FC = () => <Rankings sport={SportType.BBL} />;
const RankingsWBBL: React.FC = () => <Rankings sport={SportType.WBBL} />;

const GameRoutes: React.FC = () => (
	<HOCSession>
		<HOCSportSelection>
			<Routes>
				<Route element={<NotAuthOnlyRoute />}>
					<Route path="/" element={<Landing />} />
					<Route path="/login-app" element={<Landing />} />
					<Route path="/login-app/callback" element={<Landing />} />
					<Route path="/login" element={<Login />} />
					<Route path="/bbl" element={<LandingBBL />} />
					<Route path="/wbbl" element={<LandingWBBL />} />
					<Route path="/registration" element={<Registration />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />
				</Route>

				<Route element={<PrivateRoute />}>
					<Route path={"/bbl/tipping"} element={<TippingBBL />} />
					<Route path={"/wbbl/tipping"} element={<TippingWBBL />} />
					<Route path={"/bbl/leagues"} element={<LeaguesBBL />} />
					<Route path={"/wbbl/leagues"} element={<LeaguesWBBL />} />
					<Route path={"/wbbl/account"} element={<Account />} />
					<Route path={"/bbl/account"} element={<Account />} />
					<Route path={"/bbl/leagues/join"} element={<LeaguesJoin />} />
					<Route path={"/wbbl/leagues/join"} element={<LeaguesJoin />} />
					<Route path={"/bbl/leagues/join/:code"} element={<LeaguesJoin />} />
					<Route path={"/wbbl/leagues/join/:code"} element={<LeaguesJoin />} />
					<Route path={"/bbl/leagues/create"} element={<LeagueCreate />} />
					<Route path={"/wbbl/leagues/create"} element={<LeagueCreate />} />
					<Route path={"/bbl/rankings"} element={<RankingsBBL />} />
					<Route path={"/wbbl/rankings"} element={<RankingsWBBL />} />
					<Route path={"/wbbl/league/:league_id/:section"} element={<League />} />
					<Route path={"/bbl/league/:league_id/:section"} element={<League />} />
					<Route path={"/logout"} element={<Logout />} />
				</Route>
				<Route path={"/bbl/help/"} element={<Help game={"bbl"} />} />
				<Route path={"/wbbl/help/"} element={<Help game={"wbbl"} />} />
				<Route path={"/bbl/help/:section"} element={<Help game={"bbl"} />} />
				<Route path={"/wbbl/help/:section"} element={<Help game={"wbbl"} />} />
				<Route path={"/terms"} element={<Terms />} />
				<Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
				<Route path="*" element={<NotFound />} />
			</Routes>
		</HOCSportSelection>
	</HOCSession>
);

const OffseasonRoutes: React.FC = () => (
	<Routes>
		<Route path={"/"} element={<Offseason />} />
		<Route path={"/privacy-policy"} element={<PrivacyPolicy />} />
		<Route path={"/terms"} element={<Terms />} />
		<Route element={<RedirectToHome />} />
	</Routes>
);

ReactDOM.render(
	<StrictMode>
		<Provider store={store}>
			<Router basename={BASE_URL}>
				<Suspense fallback={<Preloader />}>
					<Alert />
					{IS_OFF_SEASON ? (
						IS_SECRET_PASSED ? (
							<GameRoutes />
						) : (
							<OffseasonRoutes />
						)
					) : (
						<GameRoutes />
					)}
					<Footer />
					<NotificationBar />
					<ErrorModal />
					<ShareModal section={"general"} />
					<BonusPointModal />
				</Suspense>
			</Router>
		</Provider>
	</StrictMode>,
	document.getElementById("root")
);
