import React from "react";
import styled from "styled-components";
import iconMore from "assets/img/icons/more.svg";
import {theme} from "assets/themes/bbl";

export const PrimaryButton = styled.button`
	cursor: pointer;
	border: 1px solid #000000;
	border-radius: 2px;
	background-color: white;
	color: ${theme.colors.primary};
	font-family: ${theme.font.montserrat};
	font-size: 14px;
	font-weight: 700;
	letter-spacing: 0;
	line-height: 20px;
	text-align: center;
	height: 40px;
	padding: 0 30px;
	transition: all 0.2s ease-in-out;
	text-transform: capitalize;
	&:hover {
		background: ${theme.colors.primary};
		color: #ffffff;
	}
	&:disabled {
		cursor: default;
		opacity: 0.5;
	}
	&:hover:disabled {
		background: inherit;
		color: inherit;
	}
`;

export const WarningButton = styled(PrimaryButton)`
	background: #f63a25;
	color: white;
	border: none;

	&:hover {
		background: #dc1515;
	}
`;

export const LoadMoreButtonWrapper = styled.button`
	width: 400px;
	height: 40px;
	background-color: ${theme.colors.tertiary};
	color: ${theme.colors.primary};
	font-size: 14px;
	line-height: 16px;
	font-weight: 600;
	font-family: ${theme.font.montserrat};
	border-radius: 2px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border: none;
	outline: none;
	margin-top: 20px;
	align-self: center;
	@media screen and (max-width: 639px) {
		width: 80%;
		margin-right: 0px;
		&:first-of-type {
			margin-right: 10px;
		}
	}
`;

const LoadMoreButtonImg = styled.img`
	width: 38px;
	vertical-align: middle;
`;

interface ILoadMore {
	onClick: () => void;
	loading: boolean;
}

export const LoadMoreButton: React.FC<ILoadMore> = ({onClick, loading}) => {
	return (
		<LoadMoreButtonWrapper onClick={onClick}>
			{loading ? <LoadMoreButtonImg src={iconMore} alt="" /> : "Load More"}
		</LoadMoreButtonWrapper>
	);
};
